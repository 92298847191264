<template>
  <div>
    <b-card>
      <form-alert :errors="serverErrors" />
      <portal to="nav-bar-right-column">
        <div class="d-flex align-items-center">
          <span v-if="formErrors" class="mr-1 d-flex align-items-center text-danger">
            <feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes
          </span>
          <b-button type="submit" variant="outline-dark" class="mr-1" @click="$emit('cancel')"> Cancel </b-button>
          <b-button type="submit" :variant="saveButtonVariant" class="mr-1" @click="onSave" :disabled="!(isDirty && $acl.can('admin.apikeys.manage'))">
            Save
          </b-button>
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="confirmDelete = true" v-if="$acl.can('admin.apikeys.manage')">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </portal>
      <validation-observer ref="apikeyForm">
        <fieldset :disabled="!$acl.can('admin.apikeys.manage')">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <text-input v-model="apikey.name" size="lg" placeholder="Name" label="Name" validation-rules="required" />
              </b-col>
              <b-col cols="6">
                <b-form-group label="Key" label-for="key" class="mr-1">
                  <b-input-group class="input-group-merge">
                    <b-form-input v-model="apikey.key" :type="passwordFieldType" class="form-control-merge" :readonly="true" />

                    <b-input-group-append is-text>
                      <div>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                          v-b-tooltip.hover.v-secondary.top
                          title="Show"
                        />
                      </div>
                      <div>
                        <b-link
                          v-b-tooltip.hover.v-secondary.top
                          variant="link"
                          title="Copy to clipboard"
                          v-clipboard:copy="apikey.key"
                          v-clipboard:success="onCopyKeySuccess"
                        >
                          <feather-icon class="cursor-pointer text-dark ml-1" icon="CopyIcon" />
                        </b-link>
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- names -->
              <b-col cols="12">
                <b-form-row>
                  <b-form-group label="Valid From" label-for="validFrom" class="mr-1">
                    <b-input-group class="input-group-merge">
                      <validation-provider #default="{ errors }" name="Valid From" rules="">
                        <date-picker v-model="apikey.validFrom" :mode="config.date.mode" :masks="config.date.masks" placeholder="Date">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" /> </template
                        ></date-picker>
                        <br /><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="Valid To" label-for="validTo">
                    <b-input-group class="input-group-merge">
                      <validation-provider #default="{ errors }" name="Valid To" rules="">
                        <date-picker v-model="apikey.validTo" :mode="config.date.mode" :masks="config.date.masks" placeholder="Date">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" /> </template
                        ></date-picker>
                        <br /><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-col>
              <b-col cols="2"> </b-col>
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="isActive" name="isActive" v-model="apikey.isActive">
                      Is Active
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-card no-body class="border mt-1">
                  <b-card-header class="p-1">
                    <b-card-title class="font-medium-1">
                      <feather-icon icon="LockIcon" size="14" />
                      <span class="align-middle ml-50">Permission</span>
                    </b-card-title>
                  </b-card-header>
                  <b-table-simple striped responsive v-for="(area, index) in permissions" :key="'sp_' + index" class="position-relative mb-0">
                    <b-thead>
                      <b-th class="action-cell-medium" align="center"> </b-th>
                      <b-th style="width:300px">
                        <span class="text-uppercase">{{ area.group }}</span>
                      </b-th>
                      <b-th>
                        <span class="text-uppercase">Permissions</span>
                      </b-th>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(section, sectionIndex) in area.sections" :key="'sp_' + index + '_' + sectionIndex">
                        <b-td class="action-cell-medium text-center">
                          <b-button size="sm" variant="link" @click="deselectAll(area, section)" v-if="isAllSelected(area, section)">Deselect</b-button>
                          <b-button size="sm" variant="primary" @click="selectAll(area, section)" v-else>Select</b-button>
                        </b-td>
                        <b-td class="py-1">
                          {{ section.name }}
                        </b-td>
                        <b-td class="py-1">
                          <div class="inline-spacing">
                            <b-form-checkbox
                              v-for="permission in section.permissions"
                              :key="'sp_' + index + '_' + sectionIndex + '_' + permission"
                              :name="permission"
                              :checked="getPermissionIndex(area.key, section.feature, permission) > -1"
                              @change="onSelectPermission($event, area.key, section.feature, permission)"
                            >
                              {{ permission }}
                            </b-form-checkbox>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </fieldset>
      </validation-observer>
    </b-card>
    <b-modal id="deleteConfirmModal" hide-footer title="Api Key" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete Api Key</h4>
        <p class="h6 font-weight-normal">Do you want to delete the Api Key?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { apikeys } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'EditApikey',
  props: ['value'],
  components: { DatePicker },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      permissions: [],
      confirmDelete: false,
      isDirty: false,
      apikey: { permissions: [] },
      formErrors: false,
      serverErrors: [],
      config: {
        date: {
          mode: 'date',
          color: 'blue',
          masks: {
            input: 'YYYY-MM-DD',
          },
        },
      },
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.original = this.value
        this.apikey = this.$_.cloneDeep(this.original)
      },
    },
    apikey: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  mounted() {},
  created() {
    this.fetchPermissions()
  },
  methods: {
    async fetchPermissions() {
      const self = this
      await apikeys.getPermissionSet().then(res => {
        self.permissions = res.data
      })
    },
    async onSave() {
      const self = this

      const validation = await self.$refs.apikeyForm.validateWithInfo()

      if (validation.isValid) {
        apikeys
          .updateApikey(self.apikey.id, self.apikey)
          .then(res => {
            self.formErrors = false
            self.serverErrors = []
            self.$emit('input', { ...self.apikey })
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.formErrors = true
      }
    },
    async onDelete() {
      const self = this
      apikeys
        .deleteApikey(self.apikey.id)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.$emit('delete')
        })
        .catch(err => {
          self.formErrors = true
          self.confirmDelete = false
          if (err.response.status == 422) {
            self.serverErrors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
    getPermissionIndex(area, feature, perm) {
      const permission = `${area}.${feature}.${perm}`

      return this.$_.findIndex(this.apikey.permissions, p => {
        return p === permission
      })
    },
    onSelectPermission(event, area, feature, perm) {
      const index = this.getPermissionIndex(area, feature, perm)

      if (event && index < 0) {
        this.apikey.permissions.push(`${area}.${feature}.${perm}`)
      }

      if (event === false && index >= 0) {
        this.apikey.permissions.splice(index, 1)
      }
    },
    isAllSelected(area, section) {
      for (let i = 0, len = section.permissions.length; i < len; i++) {
        const index = this.getPermissionIndex(area.key, section.feature, section.permissions[i])
        if (index < 0) {
          return false
        }
      }
      return true
    },
    selectAll(area, section) {
      this.$_.forEach(section.permissions, p => {
        const index = this.getPermissionIndex(area.key, section.feature, p)
        if (index < 0) {
          this.apikey.permissions.push(`${area.key}.${section.feature}.${p}`)
        }
      })
    },
    deselectAll(area, section) {
      this.$_.forEach(section.permissions, p => {
        const index = this.getPermissionIndex(area.key, section.feature, p)
        if (index >= 0) {
          this.apikey.permissions.splice(index, 1)
        }
      })
    },
    onCopyKeySuccess() {
      this.$bus.$emit('notification', { ...DefaultNotifications.infoSuccesful, text: 'Api Key copied to clipboard!', position: 'top-right', icon: 'KeyIcon' })
    },
  },
}
</script>
