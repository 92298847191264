<template>
  <div>
    <b-container fluid>
      <!-- <portal to="nav-bar-left-column">
        <page-title title="Settings" />
      </portal> -->
      <settings-nav />
    </b-container>

    <transition name="zoom-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabSelected: 0,
    }
  },
}
</script>
