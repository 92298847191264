<template>
  <div>
    <b-card>
      <form-alert :errors="serverErrors" />
      <portal to="nav-bar-right-column">
        <div class="d-flex align-items-center">
          <span v-if="formErrors" class="mr-1 d-flex align-items-center text-danger">
            <feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes
          </span>
          <b-button type="submit" variant="outline-dark" class="mr-1" @click="$emit('cancel')"> Cancel </b-button>
          <b-button type="submit" :variant="saveButtonVariant" class="mr-1" @click="onSave" :disabled="!(isDirty && $acl.can('admin.webhooks.update'))">
            Save
          </b-button>
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="confirmDelete = true" v-if="$acl.can('admin.webhooks.delete')">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </portal>
      <validation-observer ref="webhookForm">
        <fieldset :disabled="!$acl.can('admin.webhooks.update')">
          <b-form @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="webhook.name" placeholder="Name" label="Name" validation-rules="required" />
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="enabled" name="enabled" v-model="webhook.enabled">
                      Enabled
                    </b-form-checkbox>
                    <b-form-checkbox id="include-payload" name="include-payload" v-model="webhook.includePayload">
                      Include Payload
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <h3>Conditions</h3>
                <hr />
              </b-col>
              <b-col cols="12">
                <triggers v-model="webhook" :models="models" />
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col cols="12">
                <b-card no-body class="mt-1"> </b-card>
              </b-col>
            </b-row> -->
            <b-row class="mt-2">
              <b-col cols="12">
                <h3>HTTP Settings</h3>
                <hr />
              </b-col>
              <b-col cols="6">
                <text-input v-model="webhook.url" placeholder="Url" label="Url" validation-rules="required" />
              </b-col>
              <b-col cols="12">
                <headers v-model="webhook.headers" />
              </b-col>
            </b-row>
          </b-form>
        </fieldset>
      </validation-observer>
    </b-card>
    <b-modal id="deleteConfirmModal" hide-footer title="Webhook" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete Webhook</h4>
        <p class="h6 font-weight-normal">Do you want to delete the webhook?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { webhooks } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'
import Headers from './Headers.vue'
import Triggers from './Triggers.vue'

export default {
  name: 'NewWebhook',
  components: { Headers, Triggers },
  props: ['value', 'models'],
  data() {
    return {
      isDirty: false,
      confirmDelete: false,
      original: {},
      webhook: { enabled: true, includePayload: true, headers: [], events: [], entityTypeIds: [] },
      formErrors: false,
      serverErrors: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.original = this.value
        this.webhook = this.$_.cloneDeep(this.original)
      },
    },
    webhook: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  mounted() {},
  created() {},
  methods: {
    async onSave() {
      const self = this

      const validation = await self.$refs.webhookForm.validateWithInfo()

      if (validation.isValid) {
        webhooks
          .updateWebhook(self.webhook.id, self.webhook)
          .then(res => {
            self.formErrors = false
            self.serverErrors = []
            self.$emit('input', { ...self.webhook })
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.formErrors = true
      }
    },
    async onDelete() {
      const self = this
      webhooks
        .deleteWebhook(self.webhook.id)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.$emit('delete')
        })
        .catch(err => {
          self.formErrors = true
          self.confirmDelete = false
          if (err.response.status == 422) {
            self.serverErrors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
  },
}
</script>
