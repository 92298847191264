<template>
  <div>
    <b-card no-body class="card">
      <b-card-body>
        <div class="d-flex align-items-top">
          <b-img :src="require('@/assets/images/pages/59905.jpg')" class="img-left mr-3 d-none d-md-block" />

          <div class="text-left w-100">
            <h1>Internationalization</h1>
            <hr />
            <div class="mb-2">
              <h6>Active Locales</h6>
              <span class="mr-1" :class="{ h6: item.isDefault }" v-for="(item, index) in internal.locales" :key="`locale-view-${index}`"
                ><flag-icon :country-code="cultures.getCultureByName(item.locale).isoCountryCode2" class="mr-1" />
                {{ cultures.getCultureByName(item.locale).displayName }}</span
              >
            </div>
            <b-button variant="primary" @click="editLocale = true">Manage Locales</b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="editLocale"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="GlobeIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            Internationalization
          </h4>
          <b-card-text>
            Edit internationalization settings
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <page-loader v-if="loading" type="content" :fluid="true" size="small" />
        <div v-if="!loading">
          <form-alert :errors="serverErrors" />
          <fieldset :disabled="false">
            <validation-observer ref="localeForm">
              <b-form @submit.prevent>
                <b-row class="mb-1">
                  <b-col cols="6">
                    <v-select id="columns" :options="cultures.getAllCultures()" size="md" label="displayName" v-model="selectedLocale">
                      <template v-slot:option="option">
                        <flag-icon :country-code="option.isoCountryCode2" class="mr-1" />
                        {{ option.displayName }} ({{ option.culture }})
                      </template>
                      <template #selected-option="data">
                        <flag-icon :country-code="data.isoCountryCode2" class="mr-1" />
                        {{ data.displayName }} ({{ data.culture }})
                      </template>
                    </v-select>
                  </b-col>
                  <b-col cols="6">
                    <b-button variant="primary" @click="onAddLocale">Add</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-table-simple striped responsive class="position-relative">
                      <b-thead>
                        <b-th style="width:20px" class="p-1"> </b-th>
                        <b-th>
                          Locale
                        </b-th>
                        <b-th class="text-right">
                          Default
                        </b-th>
                      </b-thead>

                      <b-tr v-for="(item, index) in internal.locales" :key="`locale-${index}`">
                        <td style="width:20px" class="p-1">
                          <b-button variant="outline" size="sm" class="p-0">
                            <feather-icon
                              icon="XIcon"
                              size="16"
                              class="cursor-pointer text-danger"
                              @click="onDeleteLocale(index)"
                              v-if="internal.locales.length > 1"
                            />
                          </b-button>
                        </td>
                        <td class="pl-1">
                          <span class="mr-1"
                            ><flag-icon :country-code="cultures.getCultureByName(item.locale).isoCountryCode2" class="mr-1" />
                            {{ cultures.getCultureByName(item.locale).displayName }} - {{ item.locale }}</span
                          >
                        </td>
                        <td class="text-right">
                          <b-form-radio
                            :inline="true"
                            class="custom-control-success"
                            name="default"
                            :checked="item.isDefault ? item.locale : ''"
                            :value="item.locale"
                            @change="onDefaultChange"
                          />
                        </td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { admin } from '@/api'
import DefaultNotifications from '@/components/Notification/default'
import vSelect from 'vue-select'
import cultures from '@/libs/cultures-db'
import FlagIcon from '@/components/FlagIcon/Index.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralSettings',
  components: { vSelect, FlagIcon },
  props: ['value'],
  data() {
    return {
      cultures,
      isDirty: false,
      formErrors: false,
      serverErrors: [],
      loading: false,
      editLocale: false,
      internal: {},
      selectedLocale: null,
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.internal = this.$_.cloneDeep(newValue)
      },
    },
  },
  methods: {
    ...mapActions({ LoadEnvironment: 'app/LoadEnvironment' }),
    async onSave(e) {
      e.preventDefault()
      const self = this

      const validation = await self.$refs.localeForm.validateWithInfo()

      if (validation.isValid) {
        admin
          .updateSettings(self.internal)
          .then(res => {
            self.formErrors = false
            self.serverErrors = []
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.$emit('input', { ...self.internal })
            self.editLocale = false
            self.LoadEnvironment()
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.formErrors = true
      }
    },
    reset() {
      this.internal = this.$_.cloneDeep(this.value)
      this.editLocale = false
    },
    onDefaultChange(e) {
      for (let i = 0; i < this.internal.locales.length; i++) {
        if (this.internal.locales[i].locale === e) {
          this.internal.locales[i].isDefault = true
        } else {
          this.internal.locales[i].isDefault = false
        }
      }
    },
    onAddLocale() {
      if (this.selectedLocale) {
        var exists = this.internal.locales.filter(l => l.locale === this.selectedLocale.culture)

        if (!exists || exists.length <= 0) {
          this.internal.locales.push({
            locale: this.selectedLocale.culture,
            isDefault: false,
          })
        }
      }
    },
    onDeleteLocale(index) {
      let locale = this.internal.locales[index]
      let changeDefault = locale.isDefault

      this.internal.locales.splice(index, 1)

      if (changeDefault) {
        this.internal.locales[0].isDefault = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.img-left {
  max-height: 195px;
}
</style>
