<template>
  <div>
    <b-button @click="newApikeyModalVisible = true" variant="primary" v-if="$acl.can('admin.apikeys.manage')"
      ><feather-icon icon="PlusIcon" class="mr-25" /> Create Api Key</b-button
    >
    <b-modal
      v-model="newApikeyModalVisible"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="md"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            New Api Key
          </h4>
          <b-card-text>
            Create a new api key to access API
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <form-alert :errors="serverErrors" />
        <validation-observer ref="apiKeyForm">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <text-input v-model="apikey.name" size="lg" placeholder="Name" label="Name" validation-rules="required" />
              </b-col>
              <b-col class="col col-md-6">
                <b-form-group label="Valid From" label-for="validFrom">
                  <b-input-group class="input-group-merge w-full">
                    <validation-provider #default="{ errors }" name="Valid From" rules="" class="w-full block">
                      <date-picker :value="apikey.validFrom" :mode="config.date.mode" :masks="config.date.masks" placeholder="Date" class="w-full block">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input class="bg-white border px-2 py-1 rounded w-full" :value="inputValue" v-on="inputEvents" /> </template
                      ></date-picker>
                      <br /><small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="col col-md-6">
                <b-form-group label="Valid To" label-for="validTo">
                  <b-input-group class="input-group-merge">
                    <validation-provider #default="{ errors }" name="Valid To" rules="">
                      <date-picker :value="apikey.validTo" :mode="config.date.mode" :masks="config.date.masks" placeholder="Date">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" /> </template
                      ></date-picker>
                      <br /><small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="isActive" name="isActive" v-model="apikey.isActive">
                      Is Active
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { apikeys } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'NewApikey',
  components: { DatePicker },
  data() {
    return {
      newApikeyModalVisible: false,
      apikey: { validFrom: null, validTo: null, isActive: true },
      formErrors: false,
      serverErrors: [],
      config: {
        date: {
          mode: 'date',
          color: 'blue',
          masks: {
            input: 'YYYY-MM-DD',
          },
        },
      },
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    async onSave(e) {
      e.preventDefault()

      var self = this

      var validation = await self.$refs.apiKeyForm.validateWithInfo()

      if (validation.isValid) {
        apikeys
          .createApikey(self.apikey)
          .then(res => {
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.newApikeyModalVisible = false
            self.apikey.id = res.data.result
            self.$emit('created', { ...self.apikey })
          })
          .catch(err => {
            self.formErrors = true
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
    reset() {
      this.newApikeyModalVisible = false
      this.apikey = {}
    },
  },
}
</script>
