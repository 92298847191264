<template>
  <div>
    <b-card class="card card-project">
      <b-card-body>
        <div class="d-flex align-items-center">
          <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" class="img-left mr-3 d-none d-md-block" />

          <div class="text-left">
            <h1 class="mb-1 mt-50 text-white">{{ value.projectName }}</h1>
            <b-button variant="light" @click="editProject = true">Edit</b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="editProject"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="HomeIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            Project
          </h4>
          <b-card-text>
            Edit project details
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <page-loader v-if="loading" type="content" :fluid="true" size="small" />
        <div v-if="!loading">
          <form-alert :errors="serverErrors" />
          <fieldset :disabled="false">
            <validation-observer ref="projectForm">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12">
                    <text-input v-model="internal.projectName" placeholder="Name" label="Name" validation-rules="required" />
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { admin } from '@/api'
import DefaultNotifications from '@/components/Notification/default'

export default {
  name: 'GeneralSettingsProject',
  components: {},
  props: ['value'],
  data() {
    return {
      isDirty: false,
      formErrors: false,
      serverErrors: [],
      loading: false,
      editProject: false,
      internal: {},
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.internal = this.$_.cloneDeep(newValue)
      },
    },
  },
  methods: {
    async onSave(e) {
      e.preventDefault()
      const self = this

      const validation = await self.$refs.projectForm.validateWithInfo()

      if (validation.isValid) {
        admin
          .updateSettings(self.internal)
          .then(res => {
            self.formErrors = false
            self.serverErrors = []
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.$emit('input', { ...self.internal })
            self.editProject = false
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.formErrors = true
      }
    },
    reset() {
      this.editProject = false
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.card-project {
  background-color: $primary;
}
.img-left {
  width: 200px;
}
</style>
