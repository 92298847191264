<template>
  <div>
    <page-loader v-if="loading" type="list" :fluid="true" />
    <portal to="nav-bar-left-column">
      <page-title title="General Settings" subtitle="Manage the locales, projects..." />
    </portal>

    <b-container fluid>
      <b-row class="match-height">
        <b-col lg="4" md="12">
          <project v-model="settings" />
        </b-col>
        <b-col lg="8" sm="12">
          <localization v-model="settings" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { admin } from '@/api'
import Localization from './components/localization.vue'
import Project from './components/project.vue'

export default {
  name: 'GeneralSettings',
  components: { Localization, Project },
  data() {
    return {
      loading: false,
      settings: {},
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      let settings = await admin.getSettings()

      this.settings = settings.data
    },
  },
}
</script>
